.contact-section{
    background: #1c2237;
    padding: 40px 0;
    padding-top: 100px;
    padding-bottom: 100px;
}

.contact-section h1 {
    text-align: center;
    color: #ddd;
}

.contact-border {
    width: 120px;
    border: 1px solid;
    border-color: #f7931a;
    margin: 40px auto;
}

.contact-form {
    max-width: 600px;
    margin: auto;
    padding: 0 10px;
    overflow: hidden;
}

.form-text {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 16px 0;
    border: 0;
    background: #111;
    padding: 20px 40px;
    outline: none;
    color: #ddd;
    transition: 0.5s;
    height: 90px;
    font-size: 1em;
}

.form-textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 16px 0;
    border: 0;
    background: #111;
    padding: 20px 40px;
    outline: none;
    color: #ddd;
    transition: 0.5s;
    height: 130px;
    font-size: 1em;
}

.form-text:hover:focus {
    box-shadow: 0 0 2px 2px #f7931a;
}

.form-textarea:hover:focus {
    box-shadow: 0 0 2px 2px #f7931a;
}

textarea.contact-form {
    resize: none;
    height: 140px;
}

.form-button {
    width: 100%;
    border: 0;
    background: #388bfd;
    color: #fff;
    padding: 12px 50px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.5s;
}

.form-button:hover {
    background: #f7931a;
}
