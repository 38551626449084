.logo-cursor {
  display: inline-block;
  margin-left: 10px;
  width: 10px;
  height: 1.25rem;
  background: #f7931a;
  border-radius: 1px;
  -webkit-animation: cursor 1s infinite;
  
  animation: cursor 1s infinite;
  animation-name: cursor;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes cursor {0%{opacity: 0}50%{opacity: 1}to{opacity: 0}}
@-webkit-keyframes cursor {0%{opacity: 0}50%{opacity: 1}to{opacity: 0}}