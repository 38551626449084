.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  margin-bottom: 30px;
}

.tags li {
  float: left;
}

/* .tags:not(:last-child) {
  margin-right: 10px;
} */

.tag {
  background: #388bfd;
  border-radius: 3px 0 0 3px;
  color: #eee;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #1c2237;
  border-bottom: 13px solid transparent;
  /* border-left: 10px solid #388bfd; */
  border-left: 10px solid #388bfd;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: #f7931a;
  color: white;
  cursor: pointer;
}

.tag:hover::after {
  border-left-color: #f7931a;
}