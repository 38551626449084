* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}



/* Home page CSS 

.main-container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 50px 0px 50px;
}

h2, li {
  font-family: 'B612 Mono', monospace;
}

.about-card {
  height: 100%;
  width: 40%;
}

.bullet-points {
  padding-left: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tokyo-bench {
  max-width: 100%;
  height: 450px;
  right: 22%;
  top: 25%;
  position: relative;
}

@media screen and (max-width: 684px) {
  h2 {
    color: red;
  }

  .tokyo-bench {
    display: none;
  }
}

*/

/* Projects.js CSS */