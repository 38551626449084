.blog-container {
  height: 750px;
  text-align: center;
  line-height: 500px;
  bottom: 50%;
  background: #1c2237;
}

.blog-container h1 {
  color: white;
}