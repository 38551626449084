.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: #f7931a;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #ff8c00;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}