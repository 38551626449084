:root {
  --github: #000000;
  --linkedin: #0073b1;
  --keybase: #ff6f21;

}

.wrapper .button {
  height: 30px;
  width: 30px;
  float: left;
  margin: 0 5px;
  background: #fff;
  border-radius: 20px;
  padding-right: 5px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
  overflow: hidden;  
  text-decoration: none;
}

.wrapper .button:hover {
  width: 120px;
}

.wrapper .button .icon {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 70px;
  box-sizing: border-box;
  line-height: 37px;
  text-align: center;
  transition: all 0.3s ease-out;
  }

.wrapper .button:nth-child(1):hover .icon {
  background: var(--github);
}

.wrapper .button:nth-child(2):hover .icon {
  background: var(--linkedin);
}

.wrapper .button:nth-child(3):hover .icon {
  background: var(--keybase);
}

.wrapper .button .icon .svg {
  font-size: 19px;
  line-height: 25px;
  color: black;
}

.wrapper .button:nth-child(1) .icon .svg {
  color: var(--github);
}

.wrapper .button:nth-child(2) .icon .svg {
  color: var(--linkedin);
}

.wrapper .button:nth-child(3) .icon .svg {
  color: var(--keybase);
}

.wrapper .button:hover .icon .svg {
  color: #fff;
}

.wrapper .button span {
  font-size: 18x;
  font-weight: 300;
  line-height: -10px;
  margin-left: 5px;
  background: #fff;
  transition: all 0.3s ease-out;
}

.wrapper .button:nth-child(1) span {
  color: var(--github);
}

.wrapper .button:nth-child(2) span {
  color: var(--linkedin);
}

.wrapper .button:nth-child(3) span {
  color: var(--keybase);
}


