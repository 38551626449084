h4 {
  font-size: 1.2em;
  margin-bottom: 1em;
  line-height: 1em;
}

.bullet-points {
  margin-left: 20px;
}

#main-heading,
#sub-heading {
  color: #fff;
}

ul.no-bullet {
  -webkit-margin-before:0;
  -webkit-margin-after:0;
  -webkit-padding-start:0
 }

.inner-container {
  max-width: 60em;
  margin-left: auto;
  margin-right: auto;
}

.inner-container {
  padding-left: 2em;
  padding-right: 2em;
}

.inner-container::after {
  clear: both;
  content: '';
  display: table;
}

.hero {
  padding-top: 6em;
  padding-bottom: 6em;
}

.border {
  border-top: 1px solid #f0eeee;
}

.project-list {
  padding-top: 6em;
  padding-bottom: 1em;
}

.post-list li a {
  text-decoration: none;
}
