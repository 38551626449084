img {
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 2em;
  background-color: #f0eeee
 }

 h4 {
   font-size: 1em;
   color: #f7931a;
   margin-top: 15px;
 }

 .transition-link:hover .arrow-right {
  transform: translateX(5px);
  transition: all .25s ease;
  display: inline-block;
 }

.card img {
  max-width: 0 auto;
  margin: 0 auto;
}

.project-start {
  margin-top: 80px;
}

.transition-link {
  color: #000;
  text-decoration: none;
  margin-bottom: 1.25em;
  font-weight: 700;
}

.card {
  position: relative;
  margin-bottom: 3em;
}

.card:hover .overlay-button {
  opacity:1;
  -webkit-transition:all .5s ease;
  -moz-transition:all .5s ease;
  transition:all .5s ease;
  -webkit-transform:translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%);
  -o-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%)
}

.card .overlay-button {
  opacity:0;
  color:#fff;
  text-align:center;
  position:absolute;
  top:48%;
  left:48%;
  -webkit-transform:translate(-50%,-10%);
  -moz-transform:translate(-50%,-10%);
  -ms-transform:translate(-50%,-10%);
  -o-transform:translate(-50%,-10%);
  transform:translate(-50%,-10%);
  -webkit-transition:all .25s ease;
  -moz-transition:all .25s ease;
  transition:all .25s ease;
  z-index:3;
 }

.border-bottom {
  border-bottom: 1px solid #f0eeee;
}

.cta-button {
  border-radius: 5px;
  border: 1px solid #f7931a;
  padding: 1em 1.5em;
  display: inline-block;
  font-size: 1.5em;

  margin: 0 1em;
  margin-bottom: 1em;
  min-width: 10em;
  transition: all .25s ease;

}

.cta-button:hover {
  transform: scale(1.025);
  transition: all .25s ease;
}

.overlay {
  opacity: 0;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  transition: all .25s ease;
}

.card .overlay {
  opacity: 0;
  position: absolute;
  background-color: #000;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.card:hover .overlay {
  opacity: .8;
}

.arrow-right {
  display: inline-block;
  transition: all .25s ease;
  padding-left: 10px;
}

.project-title {
  color: #eee;
}

.no-bullet {
  list-style: none;
}

